import { faCloudBoltMoon } from "@fortawesome/pro-regular-svg-icons/faCloudBoltMoon";
import { faCloudMoon } from "@fortawesome/pro-regular-svg-icons/faCloudMoon";
import { faCloudMoonRain } from "@fortawesome/pro-regular-svg-icons/faCloudMoonRain";
import { faCloudSnow } from "@fortawesome/pro-regular-svg-icons/faCloudSnow";
import { faMoon } from "@fortawesome/pro-regular-svg-icons/faMoon";
import { faSnowflakeDroplets } from "@fortawesome/pro-regular-svg-icons/faSnowflakeDroplets";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";

import { dayjs } from "@holibob-packages/dayjs";
import { TemperatureUnit } from "@holibob-packages/graphql-types";

import { ConsumerTripWeatherForecastFragment } from "../gql-request";
import { useNextTranslation } from "../hooks";
import { useFormattedTemperature } from "../hooks/useFormattedTemperature";
import { CloudyWeather } from "../icons/Weather/Cloudy";
import { FogWeather } from "../icons/Weather/Fog";
import { HailWeather } from "../icons/Weather/Hail";
import { LightningWeather } from "../icons/Weather/Lightning";
import { PartlyCloudyWeather } from "../icons/Weather/PartlyCloudy";
import { RainWeather } from "../icons/Weather/Rain";
import { SleetWeather } from "../icons/Weather/Sleet";
import { SnowWeather } from "../icons/Weather/Snow";
import { SunnyWeather } from "../icons/Weather/Sunny";
import { WindWeather } from "../icons/Weather/Wind";
import { FontAwesomeIconRaw, RawIconDefinition } from "./FontAwesomeIconRaw";

export type WeatherIconsProps = {
    forecast?: ConsumerTripWeatherForecastFragment[] | null;
};
export function WeatherIcons({ forecast = [] }: WeatherIconsProps) {
    if (!forecast?.length) return null;
    return (
        <Container>
            {forecast.map((data) => {
                return <WeatherIcon key={data.date} forecastDay={data} />;
            })}
        </Container>
    );
}

function WeatherIcon({ forecastDay }: { forecastDay: ConsumerTripWeatherForecastFragment }) {
    const [t] = useNextTranslation("hbml");

    const weatherValueLabel = useFormattedTemperature(Math.round(forecastDay.temperature.value), {
        unit: forecastDay.temperature.unit === TemperatureUnit.Celsius ? "celsius" : "fahrenheit",
    });

    return (
        <IconContainer key={forecastDay.date}>
            <WeatherSvg type={forecastDay.type} />

            <WeatherValueLabel>{weatherValueLabel}</WeatherValueLabel>

            <WeatherLabel>{t(days[dayjs(forecastDay.date).day()])}</WeatherLabel>
        </IconContainer>
    );
}

export function WeatherSvg({ type }: { type?: ConsumerTripWeatherForecastFragment["type"] }) {
    const IconData = type ? icons[type] : null;

    if (!IconData) {
        return null;
    }

    // TODO: Remove this when all icons are migrated away from FontAwesome
    if (typeof IconData === "object" && "icon" in IconData) {
        const [width, height, , , path] = IconData.icon;
        const iconDefinition: RawIconDefinition = {
            iconName: IconData.iconName,
            prefix: IconData.prefix,
            width,
            height,
            path: Array.isArray(path) && path.length > 0 ? path.join(",") : (path as string),
        };

        return <FontAwesomeIconRaw definition={iconDefinition} />;
    }

    return <IconData width={32} height={32} fill="white" />;
}

const days = [
    "consumerTrip.weatherForecast.sun",
    "consumerTrip.weatherForecast.mon",
    "consumerTrip.weatherForecast.tue",
    "consumerTrip.weatherForecast.wed",
    "consumerTrip.weatherForecast.thu",
    "consumerTrip.weatherForecast.fri",
    "consumerTrip.weatherForecast.sat",
];

type IconDefinition = {
    icon: any;
    iconName: string;
    prefix: string;
};

const icons: Record<string, IconDefinition | React.ComponentType<SvgIconProps>> = {
    CLEAR_DAY: SunnyWeather,
    CLEAR_NIGHT: faMoon,
    CLOUDY: CloudyWeather,
    FOG: FogWeather,
    HAIL: HailWeather,
    PARTLY_CLOUDY_DAY: PartlyCloudyWeather,
    PARTLY_CLOUDY_NIGHT: faCloudMoon,
    RAIN_SNOW_SHOWERS_DAY: SleetWeather,
    RAIN_SNOW_SHOWERS_NIGHT: faSnowflakeDroplets,
    RAIN_SNOW: SleetWeather,
    RAIN: RainWeather,
    SHOWERS_DAY: RainWeather,
    SHOWERS_NIGHT: faCloudMoonRain,
    SLEET: SleetWeather,
    SNOW_SHOWERS_DAY: SnowWeather,
    SNOW_SHOWERS_NIGHT: faCloudSnow,
    SNOW: SnowWeather,
    THUNDER_RAIN: LightningWeather,
    THUNDER_SHOWERS_DAY: LightningWeather,
    THUNDER_SHOWERS_NIGHT: faCloudBoltMoon,
    THUNDER: LightningWeather,
    WIND: WindWeather,
};

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.roundness,
    width: "fit-content",
    gap: theme.spacing(2),
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), rgba(255, 255, 255, 0.1)",
    padding: theme.spacing(1, 1.5),
}));

const IconContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.light.main,
    opacity: 1,
}));

const WeatherLabel = styled("p")(({ theme }) => ({
    margin: 0,
    color: theme.palette.common.white,
    textAlign: "center",
    fontSize: 11,
    fontWeight: 400,
    textTransform: "capitalize",
    letterSpacing: 0.25,
}));

const WeatherValueLabel = styled(WeatherLabel)({
    fontSize: 10,
    textTransform: "none",
});
