import { ComponentProps } from "react";

export function FogWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g id="type=fog">
                <path
                    id="fog"
                    d="M9.49854 7.8456L9.76201 7.81649L9.88578 7.58209C10.5062 6.4072 11.3501 5.49309 12.4192 4.82837C13.484 4.16635 14.6734 3.83325 16 3.83325C17.7921 3.83325 19.315 4.42268 20.5956 5.60452C21.8814 6.79132 22.6276 8.26283 22.8395 10.0411L22.8889 10.4558L23.3057 10.481C24.8347 10.5737 26.0032 11.0993 26.8608 12.0287C27.7362 12.9775 28.1667 14.0716 28.1667 15.3333C28.1667 16.6803 27.6994 17.8114 26.7555 18.7553L27.0983 19.0982L26.7554 18.7553C25.8115 19.6993 24.6804 20.1666 23.3334 20.1666H10C8.27992 20.1666 6.83359 19.57 5.63179 18.3682C4.42999 17.1664 3.83337 15.72 3.83337 13.9999C3.83337 12.3999 4.36783 11.0299 5.44422 9.86486C6.52247 8.6978 7.86595 8.02594 9.49854 7.8456ZM20 24.8333H8.00004C7.90799 24.8333 7.83337 24.7586 7.83337 24.6666C7.83337 24.5745 7.90799 24.4999 8.00004 24.4999H20C20.0921 24.4999 20.1667 24.5745 20.1667 24.6666C20.1667 24.7586 20.0921 24.8333 20 24.8333ZM14.5 27.9999C14.5 27.9079 14.5747 27.8333 14.6667 27.8333H25.3334C25.4254 27.8333 25.5 27.9079 25.5 27.9999C25.5 28.092 25.4254 28.1666 25.3334 28.1666H14.6667C14.5747 28.1666 14.5 28.092 14.5 27.9999Z"
                    fill="white"
                    fillOpacity="0.3"
                    stroke="white"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
}
