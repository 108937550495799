import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";

import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import {
    useConsumerTripAssociateSystemTagListMutation,
    useConsumerTripRecommendedProductListQuery,
} from "../../apiHooks/graphql";
import { QuestionnaireOpenEvent } from "../../custom-events";
import { useNextTranslation } from "../../hooks/useNextTranslation";
import { Button } from "../Button";
import { HeroImageConsumerTrip } from "../HbmlComponents";
import {
    RecommendationsQuestionnaire,
    RecommendationsQuestionnaireFormInputs,
} from "../RecommendationsQuestionnaire/RecommendationsQuestionnaire";
import { isConsumerTripInterestTagId } from "../RecommendationsQuestionnaire/steps/RecommendationsInterestsStep";
import { HandleAutoPlayChange } from "./ConsumerTripStoriesContainer";
import { ConsumerTripStoriesProductsSkeleton } from "./ConsumerTripStoriesSkeleton";
import {
    ConsumerTripStoryContent,
    ConsumerTripStoryContentProps,
    StorySubtitle,
    StoryTitle,
} from "./ConsumerTripStoryContent";

type ConsumerTripStoryPersonalProps = {
    placeId: string;
    consumerTrip: Pick<HeroImageConsumerTrip, "consumerTripId" | "consumer">;
    curationId?: string;
    setAutoPlay: HandleAutoPlayChange;
    consumerTripSystemTagIdList: string[];
} & Pick<ConsumerTripStoryContentProps, "onProductClick" | "onScroll" | "onStoryProductsDisplay">;

export const ConsumerTripStoryPersonal = (props: ConsumerTripStoryPersonalProps) => {
    const [t] = useNextTranslation("hbml");
    const {
        placeId,
        consumerTrip,
        setAutoPlay,
        consumerTripSystemTagIdList,
        onProductClick,
        onScroll,
        curationId,
        onStoryProductsDisplay,
    } = props;

    const [open, setOpen] = useState(false);
    const { consumerTripId, consumer } = consumerTrip;
    const consumerTripTagIdList = getConsumerTripInterestTagIdList(consumerTripSystemTagIdList);
    const hasConsumerTripInterestAssociateTags = consumerTripTagIdList.length > 0;
    const contentRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const [consumerTripAssociateSystemTagListMutation] = useConsumerTripAssociateSystemTagListMutation();
    const {
        data,
        loading,
        refetch: refetchRecommendedProductList,
    } = useConsumerTripRecommendedProductListQuery({
        variables: { consumerTripId, placeId, curationId },
        notifyOnNetworkStatusChange: true,
    });

    const onOpen = () => {
        setAutoPlay({ shouldAutoplay: false, shouldRestart: false });
        setOpen(true);
    };

    const onOpenNoInterests = () => {
        onOpen();
        contentRef.current?.dispatchEvent(new QuestionnaireOpenEvent({ questionnaireOpenTrigger: "initialCta" }));
    };

    const onOpenWithInterests = () => {
        onOpen();
        contentRef.current?.dispatchEvent(new QuestionnaireOpenEvent({ questionnaireOpenTrigger: "customize" }));
    };

    const onClose = () => {
        setOpen(false);
        setAutoPlay({ shouldAutoplay: false, shouldRestart: true });
    };
    const onSubmit = async (data: RecommendationsQuestionnaireFormInputs) => {
        const nonConsumerTripInterestTagIdList = getConsumerTripNonInterestTagIdList(consumerTripSystemTagIdList);
        const systemTagIdList = data.interestList.map(({ value }) => value).concat(nonConsumerTripInterestTagIdList);

        await consumerTripAssociateSystemTagListMutation({
            variables: { consumerTripId, systemTagIdList },
            update(cache) {
                cache.modify({
                    id: cache.identify({ __typename: "ConsumerTripContent" }),
                    fields: {
                        systemTagIdList() {
                            return systemTagIdList;
                        },
                    },
                });
            },
        });

        void refetchRecommendedProductList();

        onClose();
    };

    const recommendedProductList = data?.consumerTripRecommendedProductList?.nodes ?? [];
    const shelfSubTitle = hasConsumerTripInterestAssociateTags
        ? t("consumerTrip.shelf.personal.withSelection.subTitle")
        : t("consumerTrip.shelf.personal.noSelection.subTitle");
    const shelfTitle = hasConsumerTripInterestAssociateTags
        ? t("consumerTrip.shelf.personal.withSelection.title")
        : t("consumerTrip.shelf.personal.noSelection.title");
    return (
        <div ref={contentRef}>
            <StorySubtitle>{shelfSubTitle}</StorySubtitle>
            <TitleWrapper>
                <Title>{shelfTitle}</Title>
                {hasConsumerTripInterestAssociateTags && (
                    <CustomiseInterestsButton onClick={onOpenWithInterests} variant="outlined" size="small">
                        {t("consumerTrip.shelf.personal.interestsCta.label")}
                    </CustomiseInterestsButton>
                )}
            </TitleWrapper>
            {loading && hasConsumerTripInterestAssociateTags ? (
                <ConsumerTripStoriesProductsSkeleton />
            ) : hasConsumerTripInterestAssociateTags ? (
                <ConsumerTripStoryContent
                    productList={recommendedProductList}
                    stopAutoPlay={() => setAutoPlay({ shouldAutoplay: false, shouldRestart: true })}
                    onProductClick={onProductClick}
                    onScroll={onScroll}
                    onStoryProductsDisplay={onStoryProductsDisplay}
                    shelfType={ConsumerTripContentItemProductListType.Personal}
                />
            ) : (
                <ConsumerTripStoryPersonalNoInterests onOpen={onOpenNoInterests} />
            )}
            {open && (
                <RecommendationsQuestionnaire
                    consumerTripId={consumerTripId}
                    consumerFirstName={consumer?.givenName ?? undefined}
                    open={open}
                    onClose={onClose}
                    onSubmit={onSubmit}
                    consumerTripSystemTagIdList={consumerTripTagIdList}
                    ref={modalRef}
                />
            )}
        </div>
    );
};

type ConsumerTripStoryPersonalNoProductsProps = {
    onOpen: () => void;
};

const ConsumerTripStoryPersonalNoInterests = ({ onOpen }: ConsumerTripStoryPersonalNoProductsProps) => {
    const [t] = useNextTranslation("hbml");

    return (
        <NoInterestsContainer>
            <NoInterestsConsumerTripStoriesProductsSkeleton numberOfProducts={8} animation={false} />
            <NoInterestsContentContainer>
                <NoInterestsButton onClick={onOpen}>{t("consumerTrip.shelf.personal.openCta.label")}</NoInterestsButton>
            </NoInterestsContentContainer>
        </NoInterestsContainer>
    );
};

const getConsumerTripInterestTagIdList = (consumerTripSystemTagIdList: string[]): string[] => {
    return consumerTripSystemTagIdList.filter(isConsumerTripInterestTagId);
};

const getConsumerTripNonInterestTagIdList = (consumerTripSystemTagIdList: string[]): string[] => {
    return consumerTripSystemTagIdList.filter((x) => !isConsumerTripInterestTagId(x));
};

const TitleWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
        gap: theme.spacing(3),
    },
    width: "100%",
    flexWrap: "wrap",
}));

const Title = styled(StoryTitle)(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
        flexGrow: 0,
    },
}));

const NoInterestsButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    background: "linear-gradient(0deg, #434343, #434343), rgba(192, 192, 192, 0.35)",
    boxShadow: "0px 6px 15px 0px rgba(17, 17, 17, 0.60)",
    borderColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 40,
    padding: theme.spacing(0.5, 3),
}));

const CustomiseInterestsButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.roundness,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingInline: theme.spacing(1.25),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&&": {
        alignSelf: "center",
        color: "white",
        background: "rgba(192, 192, 192, 0.35)",
        borderColor: "white",
        borderWidth: 2,
        fontSize: 12,
        borderRadius: theme.spacing(12.5),
        height: theme.spacing(3.5),
    },
}));

const NoInterestsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: 188,
    position: "relative",
    overflow: "hidden",
});

const NoInterestsContentContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "100%",
});

const NoInterestsConsumerTripStoriesProductsSkeleton = styled(ConsumerTripStoriesProductsSkeleton)({
    position: "absolute",
});
