import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HTMLAttributes } from "react";

import { urlProduct } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { Link } from "../../navigation";
import { ProductRatingCompact } from "../../product";
import { ImageTile } from "../ImageTile";
import { MinPriceV2 } from "../MinPriceV2";
import { Typography } from "../Typography";

export type ConsumerTripStoriesProductCardProps = {
    id: string;
    imageAssetUrl?: AssetUrl;
    name: string | null;
    price?: {
        gross?: number;
        currency?: string;
    } | null;
    slug: string | null;
    reviewRating?: number | null;
} & HTMLAttributes<HTMLDivElement>;

export function ConsumerTripStoriesProductCard({
    id,
    imageAssetUrl,
    name,
    price,
    slug,
    reviewRating,
    ...restProps
}: ConsumerTripStoriesProductCardProps) {
    const productUrl = urlProduct(slug ?? id);
    return (
        <Link href={productUrl}>
            <Container {...restProps}>
                <Image alt={name ?? ""} imageAssetUrl={imageAssetUrl} height={172} minWidth={172} maxWidth={172} />
                <TitleContainer>
                    <Title variant="title" size="small" lineClamp={2}>
                        {name}
                    </Title>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {reviewRating ? <ProductRatingCompact reviewRating={reviewRating} /> : <span />}
                        <MinPriceV2
                            size="small"
                            price={{ amount: price?.gross ?? 0, currency: price?.currency ?? "GBP" }}
                        />
                    </Box>
                </TitleContainer>
            </Container>
        </Link>
    );
}

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    width: 172,
    height: 172,
    borderRadius: theme.roundness,
    position: "relative",
}));

const Image = styled(ImageTile)(({ theme }) => ({
    borderRadius: theme.roundness,
}));

const TitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "space-between",
    backdropFilter: "blur(8px)",
    width: `calc(100% - ${theme.spacing(1.5)})`,
    bottom: theme.spacing(1.5),
    left: 0,
    marginInlineEnd: theme.spacing(1.5),
    background: "rgba(255, 255, 255, 0.85)",
    padding: theme.spacing(0.8, 1),
    gap: theme.spacing(0.5),
    borderEndEndRadius: theme.spacing(0.5),
    borderStartEndRadius: theme.spacing(0.5),
    boxSizing: "border-box",
    minHeight: 70,
}));

const Title = styled(Typography)({
    '&[data-variant="title"][data-size="small"]': {
        lineHeight: 1.24,
    },
});
