import { ComponentProps } from "react";

export function HailWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g id="type=hail">
                <path
                    id="hail"
                    d="M9.49854 7.8456L9.76201 7.81649L9.88578 7.58209C10.5062 6.4072 11.3501 5.49309 12.4192 4.82837C13.484 4.16635 14.6734 3.83325 16 3.83325C17.7921 3.83325 19.315 4.42268 20.5956 5.60451C21.8814 6.79132 22.6276 8.26283 22.8395 10.0411L22.8889 10.4558L23.3057 10.481C24.8347 10.5737 26.0032 11.0993 26.8608 12.0287C27.7362 12.9775 28.1667 14.0716 28.1667 15.3333C28.1667 16.6803 27.6994 17.8114 26.7555 18.7553L27.109 19.1089L26.7554 18.7553C25.8115 19.6993 24.6804 20.1666 23.3334 20.1666H10C8.27992 20.1666 6.83359 19.57 5.63179 18.3682C4.42999 17.1664 3.83337 15.72 3.83337 13.9999C3.83337 12.3999 4.36783 11.0299 5.44422 9.86486C6.52247 8.6978 7.86595 8.02594 9.49854 7.8456ZM9.43337 23.7999C9.43337 24.1497 9.14982 24.4333 8.80004 24.4333C8.45026 24.4333 8.16671 24.1497 8.16671 23.7999C8.16671 23.4501 8.45026 23.1666 8.80004 23.1666C9.14982 23.1666 9.43337 23.4501 9.43337 23.7999ZM13.0336 27.7999C13.0336 28.1497 12.7501 28.4333 12.4003 28.4333C12.0505 28.4333 11.767 28.1497 11.767 27.7999C11.767 27.4501 12.0505 27.1666 12.4003 27.1666C12.7501 27.1666 13.0336 27.4501 13.0336 27.7999ZM16.6332 23.7999C16.6332 24.1497 16.3497 24.4333 15.9999 24.4333C15.6501 24.4333 15.3666 24.1497 15.3666 23.7999C15.3666 23.4501 15.6501 23.1666 15.9999 23.1666C16.3497 23.1666 16.6332 23.4501 16.6332 23.7999ZM20.2335 27.7999C20.2335 28.1497 19.95 28.4333 19.6002 28.4333C19.2504 28.4333 18.9668 28.1497 18.9668 27.7999C18.9668 27.4501 19.2504 27.1666 19.6002 27.1666C19.95 27.1666 20.2335 27.4501 20.2335 27.7999ZM23.8331 23.7999C23.8331 24.1497 23.5496 24.4333 23.1998 24.4333C22.85 24.4333 22.5664 24.1497 22.5664 23.7999C22.5664 23.4501 22.85 23.1666 23.1998 23.1666C23.5496 23.1666 23.8331 23.4501 23.8331 23.7999Z"
                    fill="white"
                    fillOpacity="0.3"
                    stroke="white"
                />
            </g>
        </svg>
    );
}
