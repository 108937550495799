import { ComponentProps } from "react";

export function CloudyWeather(props: ComponentProps<"svg">) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32">
            <g id="type=cloudy">
                <path
                    id="cloud"
                    d="M8.15621 12.5453L8.56065 12.5225L8.62233 12.1222C8.90333 10.2982 9.73663 8.80225 11.1319 7.6139C12.5275 6.42517 14.143 5.83325 16 5.83325C18.093 5.83325 19.8546 6.55912 21.3144 8.01884C22.7741 9.47858 23.5 11.2402 23.5 13.3333V14.6666V15.1666H24H24.8123C25.9593 15.2052 26.9037 15.6217 27.6749 16.4203C28.4483 17.2212 28.8333 18.1824 28.8333 19.3333C28.8333 20.5121 28.4325 21.4885 27.6272 22.2938C26.8219 23.0991 25.8455 23.4999 24.6666 23.4999H8.66663C7.13395 23.4999 5.84524 22.9708 4.77158 21.9042L4.46282 22.215L4.77157 21.9042C3.69873 20.8384 3.16663 19.5546 3.16663 18.023C3.16663 16.6209 3.64147 15.3811 4.60834 14.2844C5.5659 13.1984 6.73999 12.6248 8.15621 12.5453Z"
                    fill="white"
                    fillOpacity="0.3"
                    stroke="white"
                />
            </g>
        </svg>
    );
}
